@import 'variables';

body {
  background-color: var(--main-bg-color);
  color: var(--main-font-color);
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 100;
  transition: top 0.3s;
}

.skip-link:focus {
  top: 0;
}

.App {
  font-family: 'Raleway', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--main-link-color);
}

a:hover, a:active {
  color: var(--main-link-hover-color);
}

main, .logo, .logo-small {
  margin: 0.5rem;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img, #loader img {
  height: 100px;
}

.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; /* Ensure a small gap between the switches */
}

.w3-bar button {
  line-height: 1.9rem;
}

.w3-bar button:focus-visible {
  border: 4px solid;
  border-color: var(--secondary-color); 
  outline: none;
}

.primary .title:focus-visible, .secondary .title:focus-visible {
  border: 4px solid;
  border-color: var(--second-bg-color); 
}

.second .title:focus-visible, .second .title:focus-visible {
  border: 4px solid;
  border-color: var(--primary-color);
}

.w3-button:hover, .w3-button:active { color:var(--second-font-color) !important; background-color: var(--secondary-color) !important; }

#cader-menu, #menu-btn {color:var(--second-font-color) !important; background-color:var(--second-bg-color) !important}
#menu-btn button:active, #menu-btn button:hover, #cader-menu button:active, #cader-menu button:hover { color:var(--second-font-color) !important; background-color: var(--secondary-color) !important; }

.sub-menu.primary {color:var(--muted-font-color) !important; background-color:var(--muted-bg-color) !important}
.sub-menu.primary button:active, .sub-menu.primary button:hover { color:var(--primary-font-color) !important; background-color: var(--primary-color) !important; }

.sub-menu.secondary {color:var(--muted-font-color) !important; background-color:var(--muted-bg-color) !important}
.sub-menu.secondary button:active, .sub-menu.secondary button:hover { color:var(--second-font-color) !important; background-color: var(--secondary-color) !important; }

.tile {
  padding: 0;
  margin: 0.5rem;
  border: 3px solid;

  .title {
    cursor: pointer;
    margin: 0rem;
    padding: 0 0.25rem;
    display: flex;
    align-items: center;
    line-height: 1.4rem;

    .icon {
      margin-right: 0.5rem;
      font-size: 1.5rem;
    }
  }

  .content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    padding: 0 0.5rem;
}

  .content.expanded {
      max-height: 1000px; /* You can set this to a value larger than the content's height */
      padding: 0.5rem;
  }
}

.tile.second {
  border-color:  var(--second-bg-color) !important;

  .title {
    background-color: var(--second-bg-color);
    color: var(--second-font-color);
  }
}


.tile.primary {
  border-color:  var(--primary-color) !important;

  .title {
    background-color: var(--primary-color);
    color: var(--primary-font-color);
  }
}

.tile.secondary {
  border-color:  var(--secondary-color) !important;

  .title {
    background-color: var(--secondary-color);
    color: var(--second-font-color);
  }
}

.no-padding {
  padding: 0 !important;
}

.transparent {
  background: var(--transparent-color);
}

dt {
  display: inline-block;
  font-weight: bold;
  margin-right: 10px; /* Add some space between dt and dd */
}

dd {
  display: inline-block;
  margin: 0; /* Remove default margin */
}

footer {
  background-color: var(--second-bg-color);
  color: var(--second-font-color);
  padding: 1rem;
}



@media (min-width: 601px) {
  .logo {
    float: left;
    align-items: left;
    justify-content: flex-start;
  }
  .toggle-container {
    flex-direction: column;
    float: right;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 600px) {
  #cader-menu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    z-index: 1000; /* Ensure it overlays other content */
  }

  #cader-menu.show {
    max-height: 100vh; /* Expand to full height */
  }

  /* Override w3-hide-small */
  #cader-menu.w3-hide-small {
    max-height: 0 !important; /* Ensure it stays hidden when not expanded */
    transition: none; /* Disable transition when hidden */
  }
}

