.leaflet-popup {
    margin-bottom: 60px;
}

.markerPopup .leaflet-popup-content-wrapper {
    width: 250px; /* Set the width as per your requirement */
  }

  .leaflet-touch .leaflet-bar a, .leaflet-bar a {
    width: 44px;
    height: 44px;
    line-height: 44px;
  }