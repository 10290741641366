// Define mixins for different themes
@mixin default-theme {
    --primary-color: #1275c4;
    --secondary-color: #c94d0a;

    --disabled: #ccc;

    --main-bg-color: #fff;
    --second-bg-color: #201a1a;
    --muted-bg-color: #cfcfcf;

    --main-font-color: #201a1a;
    --main-link-color: #0a5793;
    --main-link-hover-color: #c94d0a;
    --primary-font-color: #fff;
    --second-font-color: #fff;
    --muted-font-color: #363636;

    --transparent-color: rgba(255,255,255,0.8);
}

@mixin dark-theme {
    --primary-color: #0f2e3c;
    --secondary-color: #462b1c;

    --disabled: #666;

    --main-bg-color: #201a1a;
    --second-bg-color: #313a43;
    --muted-bg-color: #3e3e3e;

    --main-font-color: #1e4f65;
    --main-link-color: #1978c4;
    --main-link-hover-color: #04487f;

    --primary-font-color: #000;
    --second-font-color: #000;
    --muted-font-color: #000;

    --transparent-color: rgba(0,0,0,0.9);
}

// Apply the mixins conditionally
body {
@include default-theme;
}

body.dark {
@include dark-theme;
}


